import { configureStore } from "@reduxjs/toolkit";

// Slices
import iot from "./iot";
import devices from "./devices";
import deviceFilter from "./deviceFilter";
import deviceSearch from "./deviceSearch";
import deviceModal from "./deviceModal";
import deviceSort from "./deviceSort";
import deviceDetails from "./deviceDetails";
import deviceSelection from "./deviceSelection";
import sessionBanner from "./sessionBanner";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import team from "./team/reducer";
import frontOfRoomDeviceSettings from "../views/app/owl-settings/redux/frontOfRoomDeviceSettings.js";
import meetingOwlSettings from "../views/app/owl-settings/redux/meetingOwlSettings";
import teamSort from "./teamSort";
import teamSearch from "./teamSearch";
import subscriptionSort from "./subscriptionSort";
import subscriptionSearch from "./subscriptionSearch";
import favorites from "./favorites";
import owlConnectDevicesDrawer from "./owl-connect";
import notifications from "../features/notifications/slice.js";
import scheduler from "../features/softwareUpdateScheduler/slice.js";
import needsAttention from "../features/needs-attention/slice.js";
import userSettings from "../views/app/team/userSettings.js";
import api from "./api.js";

export default configureStore({
  reducer: {
    iot,
    devices,
    deviceFilter,
    deviceSearch,
    deviceSort,
    deviceDetails,
    deviceSelection,
    deviceModal,
    sessionBanner,
    settings,
    menu,
    team,
    frontOfRoomDeviceSettings,
    meetingOwlSettings,
    teamSort,
    teamSearch,
    subscriptionSort,
    subscriptionSearch,
    favorites,
    owlConnectDevicesDrawer,
    notifications,
    scheduler,
    needsAttention,
    userSettings,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === "test"
      ? getDefaultMiddleware()
      : getDefaultMiddleware().concat(api.middleware),
});
