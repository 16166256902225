import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onlyMeetingOwls: [],
};

const meetingOwlsSettingsSlice = createSlice({
  name: "meetingOwlSettings",
  initialState,
  reducers: {
    meetingOwlsOnlySet(state, action) {
      state.onlyMeetingOwls = action.payload;
    },
    meetingOwlDeviceSettingsCleared(state) {
      state.onlyMeetingOwls = [];
    },
  },
});

export const { meetingOwlsOnlySet, meetingOwlDeviceSettingsCleared } =
  meetingOwlsSettingsSlice.actions;

export default meetingOwlsSettingsSlice.reducer;
