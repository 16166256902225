import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAdmin: false,
  userRoleLoading: {
    isLoading: false,
    userId: "",
  },
  userStatusLoading: {
    isLoading: false,
    userId: "",
  },
};

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    isAdminSet(state, action) {
      state.isAdmin = action.payload;
    },
    userRoleLoadingSet(state, action) {
      state.userRoleLoading = action.payload;
    },
    userStatusLoadingSet(state, action) {
      state.userStatusLoading = action.payload;
    },
  },
});

export const { isAdminSet, userRoleLoadingSet, userStatusLoadingSet } =
  userSettingsSlice.actions;

export default userSettingsSlice.reducer;
