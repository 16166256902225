import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onlyFrontOfRoomDevices: [],
};

const frontOfRoomDeviceSettingsSlice = createSlice({
  name: "frontOfRoomDeviceSettings",
  initialState,
  reducers: {
    frontOfRoomDevicesOnlySet(state, action) {
      state.onlyFrontOfRoomDevices = action.payload;
    },
    frontOfRoomDeviceSettingsCleared(state) {
      state.onlyFrontOfRoomDevices = [];
    },
  },
});

export const { frontOfRoomDevicesOnlySet, frontOfRoomDeviceSettingsCleared } =
  frontOfRoomDeviceSettingsSlice.actions;

export default frontOfRoomDeviceSettingsSlice.reducer;
